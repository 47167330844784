<template>
  <div class="user-tab-overview">
    <!-- user project list -->
    <v-card class="mb-7">
      <v-data-table :headers="tableColumnHeaders" :items="checkouts" hide-default-footer disable-pagination>
        <!-- receipt number -->
        <template #[`item.receipt_number`]="{ item }">
          <div class="d-flex align-center">
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ item.gateway_data?.receipt_number ?? '-' }}
              </p>
            </div>
          </div>
        </template>

        <!-- subtotal -->
        <template #[`item.subtotal`]="{ item }">
          <div class="d-flex align-center">
            <div class="text-no-wrap">
              <p class="text--primary mb-n1">${{ item.gateway_data.subtotal }}</p>
            </div>
          </div>
        </template>

        <!-- Coupon -->
        <template #[`item.coupon`]="{ item }">
          <div class="d-flex align-center">
            <div class="text-no-wrap">
              <p class="text--primary mb-n1">
                {{ item.gateway_data.coupons_total ?? 0 }}
              </p>
            </div>
          </div>
        </template>

        <!-- HealthCoin -->
        <template #[`item.health_coin`]="{ item }">
          <div class="d-flex align-center">
            <div class="text-no-wrap">
              <p class="text--primary mb-n1">
                {{ item.gateway_data.credits_total ? Math.ceil(item.gateway_data.credits_total) : 0 }}
              </p>
            </div>
          </div>
        </template>

        <!-- Discount -->
        <template #[`item.discount`]="{ item }">
          <div class="d-flex align-center">
            <div class="text-no-wrap">
              <p class="text--primary mb-n1">
                {{ item.gateway_data.discount_total ?? 0 }}
              </p>
            </div>
          </div>
        </template>

        <!-- Total -->
        <template #[`item.total`]="{ item }">
          <div class="d-flex align-center">
            <div class="text-no-wrap">
              <p class="text--primary mb-n1">
                {{ item.gateway_data.total ?? 0 }}
              </p>
            </div>
          </div>
        </template>

        <!-- Total -->
        <template #[`item.created_at`]="{ item }">
          <div class="d-flex align-center">
            <div class="text-no-wrap">
              <p class="text--primary mb-n1">
                {{ item.created_at }}
              </p>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { getCheckouts } from '@/api/user'
import { mdiDotsVertical } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const checkouts = ref([])

    const loadCheckouts = async () => {
      try {
        const {
          data: { status, data },
        } = await getCheckouts(props.userId)

        if (status === 'success') {
          checkouts.value = data.records
        }
      } catch (error) {
        console.error(error.message)
      }
    }

    onMounted(async () => {
      if (props.userId) {
        await loadCheckouts()
      }
    })

    const tableColumnHeaders = [
      {
        text: 'Receipt Number',
        value: 'receipt_number',
        sortable: false,
      },
      { text: 'Status', value: 'status', sortable: false },
      {
        text: 'Subtotal',
        value: 'subtotal',
        sortable: false,
      },
      {
        text: 'Coupon',
        value: 'coupon',
        sortable: false,
      },
      {
        text: 'HealthCoin',
        value: 'health_coin',
        sortable: false,
      },
      {
        text: 'Discount',
        value: 'discount',
        sortable: false,
      },
      {
        text: 'Total',
        value: 'total',
        sortable: false,
      },
      {
        text: 'Date',
        value: 'created_at',
        sortable: false,
      },
    ]

    return {
      tableColumnHeaders,
      icons: {
        mdiDotsVertical,
      },

      checkouts,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
