var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-tab-overview"
  }, [_c('v-card', {
    staticClass: "mb-7"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.tableColumnHeaders,
      "items": _vm.checkouts,
      "hide-default-footer": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "item.receipt_number",
      fn: function fn(_ref) {
        var _item$gateway_data$re, _item$gateway_data;
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('div', {
          staticClass: "text-no-wrap"
        }, [_c('p', {
          staticClass: "font-weight-medium text--primary mb-n1"
        }, [_vm._v(" " + _vm._s((_item$gateway_data$re = (_item$gateway_data = item.gateway_data) === null || _item$gateway_data === void 0 ? void 0 : _item$gateway_data.receipt_number) !== null && _item$gateway_data$re !== void 0 ? _item$gateway_data$re : '-') + " ")])])])];
      }
    }, {
      key: "item.subtotal",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('div', {
          staticClass: "text-no-wrap"
        }, [_c('p', {
          staticClass: "text--primary mb-n1"
        }, [_vm._v("$" + _vm._s(item.gateway_data.subtotal))])])])];
      }
    }, {
      key: "item.coupon",
      fn: function fn(_ref3) {
        var _item$gateway_data$co;
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('div', {
          staticClass: "text-no-wrap"
        }, [_c('p', {
          staticClass: "text--primary mb-n1"
        }, [_vm._v(" " + _vm._s((_item$gateway_data$co = item.gateway_data.coupons_total) !== null && _item$gateway_data$co !== void 0 ? _item$gateway_data$co : 0) + " ")])])])];
      }
    }, {
      key: "item.health_coin",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('div', {
          staticClass: "text-no-wrap"
        }, [_c('p', {
          staticClass: "text--primary mb-n1"
        }, [_vm._v(" " + _vm._s(item.gateway_data.credits_total ? Math.ceil(item.gateway_data.credits_total) : 0) + " ")])])])];
      }
    }, {
      key: "item.discount",
      fn: function fn(_ref5) {
        var _item$gateway_data$di;
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('div', {
          staticClass: "text-no-wrap"
        }, [_c('p', {
          staticClass: "text--primary mb-n1"
        }, [_vm._v(" " + _vm._s((_item$gateway_data$di = item.gateway_data.discount_total) !== null && _item$gateway_data$di !== void 0 ? _item$gateway_data$di : 0) + " ")])])])];
      }
    }, {
      key: "item.total",
      fn: function fn(_ref6) {
        var _item$gateway_data$to;
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('div', {
          staticClass: "text-no-wrap"
        }, [_c('p', {
          staticClass: "text--primary mb-n1"
        }, [_vm._v(" " + _vm._s((_item$gateway_data$to = item.gateway_data.total) !== null && _item$gateway_data$to !== void 0 ? _item$gateway_data$to : 0) + " ")])])])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('div', {
          staticClass: "text-no-wrap"
        }, [_c('p', {
          staticClass: "text--primary mb-n1"
        }, [_vm._v(" " + _vm._s(item.created_at) + " ")])])])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }